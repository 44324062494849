import React, { useState, useEffect } from "react";
import { Image, ListGroup, Form, Badge, Button, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify"
import JobModal from "./JobModal"
import "./Home.css";

export default function Home(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchFilter, setSearchFilterState] = useState({
    search: "",
    state: "Active"
  });
  const [form, setFormState] = useState({
    ref: '',
    summary: '',
    description: '',
    address: '',
    state: 'Active'
  });

  const searchChange = e => {
    setSearchFilterState({
        ...searchFilter,
        search: e.target.value
    });
  };

  const stateChange = e => {
    setSearchFilterState({
        ...searchFilter,
        state: e
    });
  };

  const formStateChange = e => {
    setFormState({
        ...form,
        [e.target.id]: e.target.value
    });
  };

  useEffect(() => {
    function filter() {
        let list = jobs;

        if (searchFilter.search.length > 0) {
            list = list.filter((item) =>
                item.ref.toLowerCase().includes(searchFilter.search.toLowerCase()) ||
                item.address.toLowerCase().includes(searchFilter.search.toLowerCase()) ||
                item.summary.toLowerCase().includes(searchFilter.search.toLowerCase())
            )
        }

        if (searchFilter.state.length > 0) {
          list = list.filter((item) =>
              item.state === searchFilter.state
          )
        }

        setFilteredJobs(list);
    }
    filter();
  }, [searchFilter, jobs]);

  useEffect(() => {
    async function onLoad() {
  
      if (!props.isAuthenticated) {
        return;
      }

      try {

        const jobs = await loadJobs();
        setJobs(jobs);
        setFilteredJobs(jobs);

      } catch (e) {
        alert(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [props.isAuthenticated, props.isUserAdmin, props.isGeneralAdmin, props.userId]);

  function loadJobs() {
    return API.get("cmhill", "/job");
  }

  function addJobClick() {
    setModalShow(true);
  }

  function validate() {
    return form.ref.length > 0 && form.summary.length > 0 && form.description.length > 0 && form.address.length > 0;
  }

  async function createJob() {

    setIsLoading(true);

    await API.post("cmhill", "/job", {
        body: { 
            ref: form.ref,
            summary: form.summary,
            description: form.description,
            address: form.address,
            state: form.state,
            userId: props.userId
        }
    });
    window.location.reload(false)
  }

  function handleClose() {
      setModalShow(false);
  }

  function renderJobList() {
    return [{}].concat(filteredJobs).map((job, i) =>
      i !== 0 && (
        <LinkContainer key={job._id} to={`/job/${job._id}`}>
          <ListGroup.Item className={job.state === "Complete" ? "jobList completeState" : "jobList activeState"}  action>
            <div className="jobItem">
              <h6>Ref: <b>{job.ref}</b></h6>
              <Badge className="jobState" variant={job.state === "Complete" ? "success" : "primary"}>{job.state}</Badge>
              <span className="address">{job.address}</span>
              <p className="summary">{job.summary}</p>
            </div>
          </ListGroup.Item>
        </LinkContainer>
      ) 
    );
  }

  return (
    <div>
      {!isLoading
        ? <div className="home">
            <div className="searchBox">
              <Form inline>
                  <Form.Group controlId="search"> 
                      <Form.Control
                          className="textSearch"
                          type="text"
                          value={searchFilter.search}
                          placeholder="Search Jobs"
                          onChange={searchChange}
                      />
                  </Form.Group>
                  {props.isGeneralAdmin &&
                  <Form.Group controlId="state"> 
                    <ToggleButtonGroup type="radio" name="options" defaultValue={"Active"} onChange={stateChange}>
                      <ToggleButton variant="primary" value={"Active"}>Active</ToggleButton>
                      <ToggleButton variant="success" value={"Complete"}>Complete</ToggleButton>
                      <ToggleButton variant="warning" value={""}>All</ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                  }
                  {props.isGeneralAdmin &&
                    <Button className="form-group addButton" variant="primary" onClick={addJobClick}>Add New Job</Button>
                  }
              </Form>
            </div>
            {renderJobList()}
            {jobs.length === 0 &&
              <h4 className="noJobs">No Jobs</h4>
            }
            <JobModal
                modalShow={ modalShow }
                onClose={() => handleClose()}
                form={form}
                formStateChange={formStateChange}
                createJob={() => createJob()}
                validate={ !validate() }
            />
          </div>
        : <div className="loadingContainer">
            <Image src="/loading.gif"/>
          </div>
      }
    </div>
  );
}
