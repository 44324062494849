import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';

function dateFormatter(cell, row) {

    var options = { day: '2-digit', year: 'numeric', month: '2-digit' };
    const d = new Date(row.date);
  
    return d.toLocaleDateString('en-GB', options)
}

const defaultSorted = [{
    dataField: 'date',
    order: 'desc'
}];

export default ({
    data,
    onTimeClick,
    userAdmin
  }) => 
    <BootstrapTable 
        keyField="_id"
        noDataIndication="No Time Recorded"
        data={ data } 
        bootstrap4
        hover
        clickable
        rowEvents={ {
            onClick: (e, row, rowIndex) => {
                onTimeClick(row);
        } } }
        defaultSorted={ defaultSorted }
        columns={ [{
                dataField: '_id',
                text: 'ID',
                hidden: true
            }, {
                dataField: 'fullName',
                text: 'User',
                hidden: !userAdmin
            }, {
                dataField: 'date',
                text: 'Date',
                formatter: dateFormatter,
                sort: true
            },{
                dataField: 'note',
                text: 'Note'
            }, {
                dataField: 'hours',
                text: 'Hours'
        }] }>
    </BootstrapTable>;