import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NumericInput from 'react-numeric-input';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import "./Modal.css";

import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = 'dd/MM/yyyy';

export default ({
    modalShow,
    newTime,
    onClose,
    createTime,
    deleteTime,
    noteChange,
    hoursChange,
    dateChange,
    date,
    hours,
    note,
    validate,
    ...props
  }) =>
    
    <Modal backdrop="static" className="timeModal" show={modalShow} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
                { newTime ? "Add Time" : "Time Record" }            
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="date" className="dateField">
                <Form.Label>Date</Form.Label>
                { newTime
                    ?   <DayPickerInput
                            format={ FORMAT }
                            formatDate={ formatDate }
                            parseDate={ parseDate }
                            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                            value={ date } 
                            onDayChange={ dateChange } 
                        />
                    :   <Form.Control
                            type="text"
                            disabled={ true }
                            value={ formatDate(date, FORMAT) }
                        />
                }
                
            </Form.Group>
            <Form.Group controlId="hours">
                <Form.Label>Hours</Form.Label>
                {/* <Form.Control
                    type="number"
                    disabled={ !newTime }
                    step={ 0.5 }
                    value={hours}
                    onChange={hoursChange}
                /> */}
                <NumericInput
                    className="form-control"
                    value={hours}
                    min={0.5}
                    step={0.5}
                    precision={1}
                    disabled={ !newTime }
                    onChange={hoursChange}
                />

            </Form.Group>
            <Form.Group controlId="note" className="modalBodyTextArea">
                <Form.Label>Note</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea" rows="5"
                    disabled={ !newTime }
                    value={note}
                    onChange={noteChange}
                />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            { newTime
                ?   <Button variant="primary" onClick={createTime} disabled={validate}>Add Time</Button>
                :   <Button variant="danger" onClick={deleteTime}>Remove Time</Button>
            }
            <Button onClick={onClose} variant="secondary">Cancel</Button>
        </Modal.Footer>
    </Modal>;