const dev = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://fa0spvy1m1.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_dE3fnqxWn",
    APP_CLIENT_ID: "5eubkubg2cpmulls7q6t8p8rl6",
    IDENTITY_POOL_ID: "eu-west-1:55b9ad7e-12ab-4885-b325-51276daced97"
  }
};

const prod = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://fa0spvy1m1.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_dE3fnqxWn",
    APP_CLIENT_ID: "5eubkubg2cpmulls7q6t8p8rl6",
    IDENTITY_POOL_ID: "eu-west-1:55b9ad7e-12ab-4885-b325-51276daced97"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};
