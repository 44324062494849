import React, { useState, useEffect } from "react";
import { Image, Form, Button } from "react-bootstrap";
import { API } from "aws-amplify"
import UserTable from "./UserTable"
//import UserModal from "./UserModalOld"
import UserModal from "./UserModal"
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function Time(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [newUser, setNewUser] = useState(true);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchFilter, setSearchFilterState] = useState("");
    const [activeState, setActiveState] = useState(true);
    const [form, setFormState] = useState({
        id: '',
        email: '',
        name: '',
        family_name: '',
        user_admin: 0,
        general_admin: 0,
        enabled: true
    });

    const searchChange = e => {
        setSearchFilterState(e.target.value);
    };

    const formStateChange = e => {

        if(e.target.id === "user_admin" || e.target.id === "general_admin") {

            let admin = e.target.checked === true ? '1' : '0';
            
            setFormState({
                ...form,
                [e.target.id]: admin
            });
        }
        else {
            setFormState({
                ...form,
                [e.target.id]: e.target.value
            });
        }

    };

    useEffect(() => {
        function filter() {
            let list = users;
    
            if (searchFilter.length > 0) {
                list = list.filter((item) =>
                    item.email.toLowerCase().includes(searchFilter.toLowerCase()) ||
                    item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
                    item.family_name.toLowerCase().includes(searchFilter.toLowerCase())
                )
            }

            list = list.filter((item) =>
              item.enabled === activeState
            )

            setFilteredUsers(list);
        }
        filter();
    }, [searchFilter, users, activeState]);

    
    useEffect(() => {

        function loadUsers() {
            return API.get("cmhill", '/user');
        }

        async function onLoad() {
    
            if (!props.isAuthenticated) {
                return;
            }

            try {

                const users = await loadUsers();
                let flatten = [];

                users.Users.forEach(user => {

                    let flatUser = {
                        username: user.Username,
                        createdDate: user.UserCreateDate,
                        modifiedDate: user.UserLastModifiedDate,
                        enabled: user.Enabled,
                        status: user.UserStatus
                    };

                    user.Attributes.forEach(attr => { 
                        flatUser[ attr.Name ] = attr.Value;
                    });

                    flatten.push(flatUser);

                });

                setUsers(flatten);
                setFilteredUsers(flatten);

            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [props.isAuthenticated, props.isUserAdmin, props.isGeneralAdmin, props.userId]);

    function validate() {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return form.email.length > 0 && form.name.length > 0 && form.family_name.length > 0 && regexp.test(form.email);
    }

    function onUserClick(row) {
        setFormState({
            id: row.username,
            email: row.email,
            name: row.name,
            family_name: row.family_name,
            user_admin: row["custom:user_admin"],
            general_admin: row["custom:general_admin"],
            enabled: row.enabled,
            status: row.status
        });
        setNewUser(false);
        setModalShow(true);
    }

    function addUserClick() {
        setNewUser(true);
        setFormState({
            id: '',
            email: '',
            name: '',
            family_name: '',
            user_admin: '0',
            general_admin: '0'
        });
        setModalShow(true);
    }

    async function createUser() {

        setIsLoading(true);

        try {

            let user = { 
                email: form.email,
                name: form.name,
                family_name: form.family_name,
                user_admin: form.user_admin,
                general_admin: form.general_admin
            }

            if (form.status === "FORCE_CHANGE_PASSWORD") {
                user.resend = true;
            }

            let resp = await API.post("cmhill", "/user", {
                body: user
            })
            .then(result => result)
            .catch(err => err.response);

            if(resp.status) {
                alert(resp.data);
                setIsLoading(false);
            } else {
                window.location.reload(false);
            }

        } catch(e) {
            setIsLoading(false);
        }
        
    }

    async function updateUser() {

        setIsLoading(true);

        try {

            let resp = await API.put("cmhill", "/user", {
                body: { 
                    cognitoId: form.id,
                    email: form.email,
                    name: form.name,
                    family_name: form.family_name,
                    user_admin: form.user_admin,
                    general_admin: form.general_admin
                }
            })
            .then(result => result)
            .catch(err => err.response);

            if(resp.status) {
                alert(resp.data);
                setIsLoading(false);
            } else {
                window.location.reload(false);
            }

        } catch(e) {
            setIsLoading(false);
        }
    }

    function renderUsersList() {
        return (
            <UserTable data={ filteredUsers } onUserClick={ onUserClick } />
        );
    }

    function handleClose() {
        setUser({});
        setModalShow(false);
    }

    function handleActiveState() {
        setActiveState(!activeState);
    }

    async function enableUser() {
        setIsLoading(true);
        try {
            let resp = await API.put("cmhill", "/user/enable", {
                body: { cognitoId: form.id }
            })
            .then(result => result)
            .catch(err => err.response);

            if(resp.status) {
                alert(resp.data);
                setIsLoading(false);
            } else {
                window.location.reload(false);
            }
        } catch(e) {
            setIsLoading(false);
        }
    }
    
    async function disableUser() {
        setIsLoading(true);
        try {
            let resp = await API.put("cmhill", "/user/disable", {
                body: { cognitoId: form.id }
            })
            .then(result => result)
            .catch(err => err.response);

            if(resp.status) {
                alert(resp.data);
                setIsLoading(false);
            } else {
                window.location.reload(false);
            }
        } catch(e) {
            setIsLoading(false);
        }
    }
    
    return (
        <div>
            {!isLoading
                ? <div className="home">
                    <div className="searchBox">
                        <Form inline>
                            <Form.Group controlId="search"> 
                                <Form.Control
                                    className="textSearch"
                                    type="text"
                                    value={searchFilter}
                                    placeholder="Search Users"
                                    onChange={searchChange}
                                />
                            </Form.Group>
                            <Button variant={activeState ? "danger"  : "success"} onClick={handleActiveState}>{activeState ? "Show Inactive Users"  : "Show Active Users"}</Button>
                            <Button className="addButton" variant="primary" onClick={addUserClick}>Add User</Button>
                        </Form>
                    </div>
                    {renderUsersList()}
                    <UserModal
                        modalShow={ modalShow }
                        onClose={() => handleClose()}
                        form={form}
                        user={user}
                        formStateChange={formStateChange}
                        createUser={() => createUser()}
                        updateUser={() => updateUser()}
                        enableUser={() => enableUser()}
                        disableUser={() => disableUser()}
                        validate={ !validate() }
                        newUser = { newUser }
                    />
                </div>
                
                : <div className="loadingContainer">
                    <Image src="/loading.gif"/>
                </div>
            }
        </div>
    );
}
