import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Modal.css";



export default ({
    modalShow,
    newNote,
    onClose,
    createNote,
    deleteNote,
    noteTextChange,
    noteText,
    ...props
  }) =>
    
    <Modal backdrop="static" className="noteModal" show={modalShow} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
                Add Note
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="note" className="modalBodyTextArea">
                <Form.Label>Note</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea" rows="5"
                    disabled={ !newNote }
                    value={ noteText }
                    onChange={ noteTextChange }
                />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            { newNote
                ?   <Button variant="primary" onClick={createNote} disabled={noteText.length < 5}>Add Note</Button>
                :   <Button variant="danger" onClick={deleteNote}>Remove Note</Button>
            }
            <Button onClick={onClose} variant="secondary">Cancel</Button>
        </Modal.Footer>
    </Modal>;