import React from "react";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";

export default ({
    modalShow,
    onClose,
    form,
    formStateChange,
    newUser,
    createUser,
    updateUser,
    enableUser,
    disableUser,
    validate,
    ...props
  }) =>
    <Modal show={modalShow} onHide={onClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
                {newUser ? "New User" : form.name + " " + form.family_name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                value={ form.email }
                                onChange={ formStateChange }
                                disabled={ !newUser }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={ form.name }
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="family_name">
                            <Form.Label>Surname</Form.Label>
                            <Form.Control
                                type="text"
                                value={ form.family_name }
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="checkboxes">
                    <Col>
                        <Form.Group controlId="generalAdmin">
                            <Form.Check 
                                defaultChecked={ form.general_admin === "1"}
                                type="switch" 
                                id="general_admin" 
                                label="General Admin"
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                        <Form.Group controlId="userAdmin">
                            <Form.Check 
                                defaultChecked={ form.user_admin === "1"}
                                type="switch" 
                                id="user_admin" 
                                label="User Admin"
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            {form.status === "FORCE_CHANGE_PASSWORD" &&
                <Button variant="primary" onClick={createUser}>Resend Password</Button>
            }
            {newUser
                ?   <Button variant="primary" onClick={createUser} disabled={validate}>Create User</Button>
                :   <Button variant="success" onClick={updateUser} disabled={validate}>Update User</Button>
            }
            {form.enabled
                ? <Button variant="danger" onClick={disableUser}>Disable User</Button>
                : <Button variant="success" onClick={enableUser}>Enable User</Button>
            }
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>;