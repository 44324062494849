import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Image from 'react-bootstrap/Image'
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { Link } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";

export default function Login(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [user, setUser] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validatePasswordResetForm() {
    return fields.newPassword.length > 0 && fields.newPassword === fields.confirmPassword;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {

      Auth.signIn(fields.email, fields.password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setNewUser(true);
            setUser(user);
            setIsLoading(false);
          } else {
            Auth.currentUserInfo()
              .then(response => {

                if (response.attributes["custom:user_admin"] === "1") {
                  props.setIsUserAdmin(true);
                }

                if (response.attributes["custom:general_admin"] === "1") {
                  props.setIsGeneralAdmin(true);
                }
                
                props.setUserId(response.username);
                props.userHasAuthenticated(true);
              }).catch(e => {
                alert(e.message);
                setIsLoading(false);
              });            
          }
        }).catch(e => {
          alert(e.message);
          setIsLoading(false);
        });

    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  async function handleReset(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      
      Auth.completeNewPassword(
          user,                    // the Cognito User Object
          fields.newPassword       // the new password
      ).then(user => {
        Auth.currentUserInfo()
          .then(response => {
            if (response.attributes["custom:user_admin"] === "1") {
              props.setIsUserAdmin(true);
            }

            if (response.attributes["custom:general_admin"] === "1") {
              props.setIsGeneralAdmin(true);
            }
            
            props.setUserId(response.username);
            props.userHasAuthenticated(true);
          }).catch(e => {
            alert(e.message);
            setIsLoading(false);
          });     
      }).catch(e => {
        alert(e.message);
        setIsLoading(false);
      });

    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  function renderResetPassword() {
    return (
      <form onSubmit={handleReset}>
        <Form.Group controlId="newPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            autoFocus
            value={fields.newPassword}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
          <Form.Text>
            Password must contain a mix of uppercase and lowercase letters, numbers and symbols
          </Form.Text>
        </Form.Group>
        
        <LoaderButton
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validatePasswordResetForm()}
        >
          Reset Password
            </LoaderButton>
      </form>
    );
  }

  function renderLogin() {
    return (
      <form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
            </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Login">
      {!newUser
        ? <div className="logo">
            <Image fluid src="/logo.jpg"/>
            {renderLogin()}
          </div>
        : renderResetPassword()
      }
    </div>
  );
}
