import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';

const defaultSorted = [{
    dataField: 'email',
    order: 'asc'
}];
  

export default ({
    data,
    onUserClick
  }) => 
    <BootstrapTable 
        keyField="username"
        noDataIndication="No Users Exist"
        data={ data } 
        bootstrap4
        hover
        clickable
        rowEvents={ {
            onClick: (e, row, rowIndex) => {
                onUserClick(row);
        } } }
        defaultSorted={ defaultSorted }
        columns={ [{
                dataField: 'username',
                text: 'Username',
                hidden: true
            }, {
                dataField: 'name',
                text: 'First Name',
                sort: true,
                width: 500
            }, {
                dataField: 'family_name',
                text: 'Surname',
                sort: true
            }, {
                dataField: 'email',
                text: 'Email',
                sort: true
            }, {
                dataField: 'custom:general_admin',
                text: 'General Admin',
                sort: true
            }, {
                dataField: 'custom:user_admin',
                text: 'User Admin',
                sort: true
            }, {
                dataField: 'status',
                text: 'Status'
            }, {
                dataField: 'enabled',
                text: 'Enabled'
            }
        ] }>
    </BootstrapTable>;