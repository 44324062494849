import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/jobs/Home";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import NotFound from "./containers/NotFound";

import Job from "./containers/job/Job";
import Time from "./containers/time/Time";
import Users from "./containers/users/Users";

export default function Routes({ appProps }) {
  return (
    <Switch>
      
      <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <AuthenticatedRoute path="/job/:id" exact component={Job} appProps={appProps} />
      <AuthenticatedRoute path="/time" exact component={Time} appProps={appProps} />

      <AuthenticatedRoute path="/users" exact component={Users} appProps={appProps} />

      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} appProps={appProps} />
  

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
