import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

function dateFormatter(cell, row) {

    var options = { day: '2-digit', year: 'numeric', month: '2-digit' };
    const d = new Date(row.date);
  
    return d.toLocaleDateString('en-GB', options)
}

// function priceFormatter(column, colIndex, { text }) {
    
//     var response = "";

//     alert(JSON.stringify(column));
    
//     return (
//       <h5><strong>$$ { column.text } $$</strong></h5>
//     );
// }

const defaultSorted = [{
    dataField: 'date',
    order: 'desc'
}];

const { ExportCSVButton } = CSVExport;

export default ({
    data,
    onTimeClick,
    generalAdmin
  }) => 
  <ToolkitProvider
    keyField="_id"
    noDataIndication="No Time Recorded"
    data={ data } 
    bootstrap4
    hover
    clickable
    exportCSV
    rowEvents={ {
        onClick: (e, row, rowIndex) => {
            onTimeClick(row);
    } } }
    defaultSorted={ defaultSorted }
    columns={ [{
            dataField: '_id',
            text: 'ID',
            hidden: true
        }, {
            dataField: 'fullName',
            text: 'User',
            hidden: !generalAdmin
        }, {
            dataField: 'date',
            text: 'Date',
            formatter: dateFormatter,
            sort: true
        },{
            dataField: 'note',
            text: 'Note'
        },{
            dataField: 'ref',
            text: 'Job',
            sort: true
        }, {
            dataField: 'hours',
            text: 'Hours'
    }] }
    >
    {
        props => (
        <div>
            {generalAdmin &&
                <ExportCSVButton className="btn-success exportButton" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
            }
            <BootstrapTable { ...props.baseProps } />
        </div>
        )
    }
    </ToolkitProvider>;