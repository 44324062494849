import React from "react";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";

export default ({
    modalShow,
    onClose,
    form,
    formStateChange,
    createJob,
    validate,
    ...props
  }) =>
    <Modal show={modalShow} onHide={onClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
                New Job
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col sm={6}>
                        <Form.Group controlId="ref">
                            <Form.Label>Ref</Form.Label>
                            <Form.Control
                                type="text"
                                value={ form.ref }
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="summary">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control
                                type="text"
                                value={ form.summary }
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={ form.address }
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                type="text"
                                value={ form.description }
                                onChange={ formStateChange }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={createJob} disabled={validate}>Create Job</Button>
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>;