import React, { useState, useEffect } from "react";
import { Image, Button, Alert, Badge } from "react-bootstrap";
import { API, Auth } from "aws-amplify"
import TimeTable from "./TimeTable"
import NoteTable from "./NoteTable"
import "./Job.css";
import TimeModal from "./TimeModal";
import NoteModal from "./NoteModal";

export default function Job(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({ job: {}, time: [], notes: [], users: []});
  const [userId, setUserId] = useState('');
  const [admin, setAdmin] = useState(false);
  const [timeModal, setTimeModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [newTime, setNewTime] = useState(true);
  const [newNote, setNewNote] = useState(true);
  const [time, setTime] = useState({}); 
  const [note, setNote] = useState({});
  const [noteText, setNoteText] = useState('');
  const [date, setDate] = useState(new Date());
  const [form, setFormState] = useState({
    note: '',
    hours: 0
  });

  const timeNoteChange = e => {
    setFormState({
      ...form,
      note: e.target.value
    });
  };

  const hoursChange = e => {

    let hour = Math.round(e*2)/2;

    setFormState({
      ...form,
      hours: hour
    });
  };

  const noteTextChange = e => {
    setNoteText(e.target.value);
  };

  const dateChange = event => {
    setDate(event);
  }

  function validate() {
    return form.note.length > 0 && form.hours > 0 && date !== undefined;
  }

  function handleClose() {
    setNoteModal(false);
    setTimeModal(false);
    setTime({});
    setNote({});
    setNewTime(true);
    setNewNote(true);
    setNoteText('');
    setDate(new Date())
    setFormState({
      hours: 0,
      note: ''
    });
  }

  async function updateJob() {

    setIsLoading(true);

    const body = {
      "id": state.job._id,
      "state": state.job.state === "Complete" ? "Active" : "Complete"
    }

    await API.put("cmhill", "/job", {
      body: body
    });

    window.location.reload(false)
  }

  function onTimeClick(row) {
    setNewTime(false);
    setTime(row);
    setDate(new Date(row.date))
    setFormState({
      hours: row.hours,
      note: row.note
    });
    setTimeModal(true);
  }

  function onNoteClick(row) {
    setNewNote(false);
    setNote(row);
    setNoteText(row.note);
    setNoteModal(true);
  }

  async function createTime() {

    setIsLoading(true);
    setTimeModal(false);

    const time = {
      "jobId": props.match.params.id,
      "userId": userId,
      "date": date, 
      "hours": form.hours, 
      "note": form.note
    }

    await API.post("cmhill", "/time", {
      body: time
    });

    window.location.reload(false)
    
  }

  async function deleteTime() {

    setIsLoading(true);
    setTimeModal(false);

    const body = {
      "id": time._id,
      "deleted": true
    }

    await API.put("cmhill", "/time", {
      body: body
    });

    window.location.reload(false)

  }

  async function createNote() {

    setIsLoading(true);
    setNoteModal(false);

    const note = {
      "jobId": props.match.params.id,
      "userId": userId,
      "type": "NOTE",
      "note": noteText
    }

    await API.post("cmhill", "/note", {
      body: note
    });

    window.location.reload(false)

  }

  async function deleteNote() {

    setIsLoading(true);
    setNoteModal(false);

    const body = {
      "id": note._id,
      "deleted": true
    }

    await API.put("cmhill", "/note", {
      body: body
    });

    window.location.reload(false)

  }

  useEffect(() => {

    function loadUsers() {
      return API.get("cmhill", '/user');
    }

    function loadJob() {
      return API.get("cmhill", `/job/${props.match.params.id}`);
    }

    function loadTime() {
      const stringParam = {
        jobId: props.match.params.id,
        deleted: false
      };

      if(!admin) {
          stringParam.userId = userId
      }

      const params = { // OPTIONAL
          queryStringParameters: stringParam
      };
      return API.get("cmhill", '/time', params);
    }

    function loadNotes() {
      const stringParam = {
        jobId: props.match.params.id,
        deleted: false
      };

      const params = { // OPTIONAL
          queryStringParameters: stringParam
      };
      return API.get("cmhill", '/note', params);
    }

    async function onLoad() {
  
      if (!props.isAuthenticated) {
        return;
      } else {
        await Auth.currentUserInfo()
            .then(response => {

                if (response.attributes["custom:general_admin"] === "1") {
                    setAdmin(true);
                }
                setUserId(response.username);
        });  
      }

      try {

        const [job, time, notes, users] = await Promise.all([loadJob(), loadTime(), loadNotes(), loadUsers()]);

        const simplifiedUsers = [];

        for (let i = 0; i < users.Users.length; i++) {
          let user = {};

          let name = users.Users[i].Attributes.filter(function(item) { return item.Name === 'name'; });
          let family_name = users.Users[i].Attributes.filter(function(item) { return item.Name === 'family_name'; });

          user.id = users.Users[i].Username;
          user.fullName = name[0].Value + " " + family_name[0].Value;

          simplifiedUsers.push(user);
        }

        let mergedTime = [];

        for (let i = 0; i < time.length; i++) {
          mergedTime.push({
            ...time[i],
            ...(simplifiedUsers.find((itmInner) => itmInner.id === time[i].userId))
          });
        }

        let mergedNotes = [];

        for (let i = 0; i < notes.length; i++) {
          mergedNotes.push({
            ...notes[i],
            ...(simplifiedUsers.find((itmInner) => itmInner.id === notes[i].createdBy))
          });
        }


        setState({
          users: simplifiedUsers,
          job: job,
          time: mergedTime,
          notes: mergedNotes
        });

      } catch (e) {
        alert(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [props.isAuthenticated, props.isUserAdmin, props.match.params.id, admin, userId]);

  function renderTimeList() {
    return (
      <TimeTable data= { state.time } onTimeClick= {onTimeClick} userAdmin={ admin }/>
    );
  }

  function renderNoteList() {
    return (
      <NoteTable data={ state.notes } userAdmin={ admin } onNoteClick={onNoteClick} />
    );
  }

  return (
    <div>
      {!isLoading
        ? <div className="job">
            <Button size="lg" variant="secondary" onClick={() => props.history.push("/")}>Back</Button>
            {props.isGeneralAdmin &&
              <div className="updateButton">
                {state.job.state === "Active"
                  ? <Button className="complete" variant="success" onClick={() => updateJob()}>Complete Job</Button>
                  : <Button className="complete" variant="primary" onClick={() => updateJob()}>Make Active</Button>
                }
              </div>
            }
            <Alert className={state.job.state === "Complete" ? "jobPanel noHover completeState" : "jobPanel noHover activeState"} >
              <Alert.Heading>
                {state.job.ref}
                <Badge className="jobState" variant={state.job.state === "Complete" ? "success" : "primary"}>{state.job.state}</Badge>
              </Alert.Heading>
              <p>{state.job.address}</p>
              <hr />
              <p>
                <b>{state.job.summary}</b>
                <br />
                {state.job.description}
              </p>
            </Alert>
            <div className="hr-sect">Time Section</div>
            {state.job.state === "Active" &&
              <Button className="newEntry" size="lg" block variant="primary" onClick={() => setTimeModal(true)}>Add Time Entry</Button>
            }
              { renderTimeList() }
            <div className="hr-sect">Note Section</div>
            {state.job.state === "Active" &&
              <Button className="newEntry" size="lg" block variant="success" onClick={() => setNoteModal(true)}>Add New Note</Button>
            }
            { renderNoteList() }
            <TimeModal
              modalShow={timeModal}
              onClose={ () => handleClose() }
              newTime={ newTime }
              createTime={ () => createTime() }
              deleteTime={ () => deleteTime() }
              noteChange={ timeNoteChange }
              hoursChange={ hoursChange }
              dateChange={ dateChange }
              date={ date }
              hours={ form.hours }
              note={ form.note }
              validate={ !validate() }
            />
            <NoteModal
              modalShow={ noteModal }
              onClose={ () => handleClose() }
              newNote={ newNote }
              createNote={ () => createNote() }
              deleteNote={ () => deleteNote() }
              noteTextChange={ noteTextChange }
              noteText={ noteText }
            />
          </div>
          
        : <div className="loadingContainer">
            <Image src="/loading.gif"/>
          </div>
      }
    </div>
  );
}
