import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isGeneralAdmin, setIsGeneralAdmin] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {

      Auth.currentSession()
        .then(user => {
          Auth.currentUserInfo()
            .then(response => {

              if (response.attributes["custom:user_admin"] === "1") {
                setIsUserAdmin(true);
              }

              if (response.attributes["custom:general_admin"] === "1") {
                setIsGeneralAdmin(true);
              }

              setUserId(response.username);
            })
        })
        .then(user => userHasAuthenticated(true));
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setIsUserAdmin(false);
    setIsGeneralAdmin(false);
    setUserId('');
    props.history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App container">
        {isAuthenticated &&
          <Navbar collapseOnSelect expand="md">
             <Navbar.Brand href="/">
                <img
                  src="/logo.jpg"
                  height="50"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Nav>
                <LinkContainer exact to="/">
                  <Nav.Link className="alignRight">Jobs</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/time">
                  <Nav.Link className="alignRight">Time</Nav.Link>
                </LinkContainer>
                {isUserAdmin &&
                  <LinkContainer to="/users">
                    <Nav.Link className="alignRight">Users</Nav.Link>
                  </LinkContainer>
                }
                <Nav.Link className="alignRight" onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        }
        <Routes appProps={{ isAuthenticated, userHasAuthenticated, isUserAdmin, setIsUserAdmin, isGeneralAdmin, setIsGeneralAdmin, userId, setUserId }} />
      </div>
    )
  );
}

export default withRouter(App);
