import React, { useState, useEffect } from "react";
import { Image, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Glyphicon from '@strongdm/glyphicon'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { API } from "aws-amplify"
import TimeTable from "./TimeTable"
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function Time(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [userId, setUserId] = useState('0');
    const [time, setTime] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [filteredTime, setFilteredTime] = useState([]);
    const [dates, setDates] = useState({
        startDate: moment().subtract(14, 'days'),
        endDate: moment().add(1, 'days')
    });
    const [searchFilter, setSearchFilterState] = useState({
        search: "",
        user: "",
        job: "",
        startDate: moment().subtract(14, 'days').set({hour:0,minute:0,second:0,millisecond:0}),
        endDate: moment().add(1, 'days')
    });

    const searchChange = e => {
        setSearchFilterState({
            ...searchFilter,
            search: e.target.value
        });
    };

    const userChange = e => {
        setSearchFilterState({
            ...searchFilter,
            user: e.target.value
        });
    };

    const jobChange = e => {
        setSearchFilterState({
            ...searchFilter,
            job: e.target.value
        });
    };

    function dateChange(start, end) {
        setDates({
            startDate: start,
            endDate: end
        });
        setSearchFilterState({
            ...searchFilter,
            startDate: start,
            endDate: end
        });
    };

    useEffect(() => {
        function filter() {
            let list = time;
    
            if (searchFilter.search.length > 0) {
                list = list.filter((item) =>
                    item.note.toLowerCase().includes(searchFilter.search.toLowerCase())
                )
            }
    
            if (searchFilter.user.length > 0) {
                list = list.filter((item) =>
                    item.userId === searchFilter.user); 
            }

            if (searchFilter.job.length > 0) {
                list = list.filter((item) =>
                    item.jobId === searchFilter.job); 
            }

            list = list.filter((item) => moment(item.date) > moment(searchFilter.startDate));
            list = list.filter((item) => moment(item.date) < moment(searchFilter.endDate));

            setFilteredTime(list);
        }
        filter();
    }, [searchFilter, time]);

    
    useEffect(() => {

        function loadUsers() {
            return API.get("cmhill", '/user');
        }

        function loadTime() {
            const stringParam = {
                deleted: false
            };
    
            if(!admin) {
                stringParam.userId = userId
            }
    
            const params = { // OPTIONAL
                queryStringParameters: stringParam
            };
            return API.get("cmhill", '/time', params);
        }

        function loadJobs() {
            const stringParam = {
                deleted: false
            };
    
            if(!admin) {
                stringParam.userId = userId
            }
    
            const params = { // OPTIONAL
                queryStringParameters: stringParam
            };
            return API.get("cmhill", '/job', params);
        }

        async function onLoad() {
    
            if (!props.isAuthenticated) {
                return;
            } else {
                await Auth.currentUserInfo()
                    .then(response => {

                        if (response.attributes["custom:general_admin"] === "1") {
                            setAdmin(true);
                        }
                        setUserId(response.username);
                });  
            }

            try {

                const [time, users, jobs] = await Promise.all([loadTime(), loadUsers(), loadJobs()]);

                setJobs(jobs);

                const simpleJobs = [];

                for (let i = 0; i < jobs.length; i++) {
                    let job = {};

                    job.jobId = jobs[i]._id;
                    job.ref = jobs[i].ref;

                    simpleJobs.push(job);
                }
                
                const simplifiedUsers = [];

                for (let i = 0; i < users.Users.length; i++) {
                    let user = {};

                    let name = users.Users[i].Attributes.filter(function(item) { return item.Name === 'name'; });
                    let family_name = users.Users[i].Attributes.filter(function(item) { return item.Name === 'family_name'; });

                    user.id = users.Users[i].Username;
                    user.fullName = name[0].Value + " " + family_name[0].Value;

                    simplifiedUsers.push(user);
                }

                setUsers(simplifiedUsers);

                let mergedTimeAndUsers = [];
                let mergedTime = [];

                // Merge users
                for (let i = 0; i < time.length; i++) {
                    mergedTimeAndUsers.push({
                        ...time[i],
                        ...(simplifiedUsers.find((itmInner) => itmInner.id === time[i].userId))
                    });
                }

                // Merge Jobs
                for (let i = 0; i < mergedTimeAndUsers.length; i++) {
                    mergedTime.push({
                        ...mergedTimeAndUsers[i],
                        ...(simpleJobs.find((itmInner) => itmInner.jobId === mergedTimeAndUsers[i].jobId))
                    });
                }

                setTime(mergedTime);
                setFilteredTime(mergedTime);

            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [props.isAuthenticated, props.isUserAdmin, admin, userId]);

    function onTimeClick(row) {
        alert(JSON.stringify(searchFilter));
    }

    function renderTimeList() {
        return (
            <TimeTable data={ filteredTime } onTimeClick={ onTimeClick } generalAdmin={ admin }/>
        );
    }

    function renderTotalTime() {

        let total = 0

        if(filteredTime.length > 0) {
            total = filteredTime.map(time => time.hours).reduce((prev, next) => Number(prev) + Number(next));
        }

        return (
            <span><b>Total Hours: {total}</b></span>
        );
    }

    let start = dates.startDate.format('DD MMM YYYY');
    let end = dates.endDate.format('DD MMM YYYY');
    let label = start + ' - ' + end;

    if (start === end) {
      label = start;
    }

    return (
        <div>
            {!isLoading
                ? <div className="home">
                    <div className="searchBox">
                        <Form inline>
                            <Form.Group controlId="search"> 
                                <Form.Control
                                    className="textSearch"
                                    type="text"
                                    value={searchFilter.search}
                                    placeholder="Search Notes"
                                    onChange={searchChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="job"> 
                                <Form.Control
                                    as="select"
                                    className="jobSearch"
                                    placeholder="Job"
                                    onChange={jobChange}>
                                    <option key="0" value="">All Jobs</option>
                                    {jobs.map((e, key) => {
                                        return <option key={key} value={e._id}>{e.ref}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {admin &&
                                <Form.Group controlId="user"> 
                                    <Form.Control
                                        as="select"
                                        className="userSearch"
                                        placeholder="User"
                                        onChange={userChange}>
                                        <option key="0" value="">All Users</option>
                                        {users.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.fullName}</option>;
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            }
                            <DateRangePicker
                                initialSettings={{
                                    startDate: moment().subtract(14, 'days').toDate(),
                                    endDate: moment().add(1, 'days').toDate(),
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                    },
                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    }
                                }}
                                onCallback={dateChange} >
                                <div id="reportrange" className="btn dateButton form-control dateFilter">
                                    <Glyphicon glyph="calendar" className="datePicker" />
                                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                                </div>
                            </DateRangePicker>
                            
                        </Form>
                    </div>
                    {renderTimeList()}
                    {renderTotalTime()}
                </div>
                : <div className="loadingContainer">
                    <Image src="/loading.gif"/>
                </div>
            }
        </div>
    );
}
