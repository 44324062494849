import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';

function noteFormatter(cell, row) {

    var options = { day: '2-digit', year: 'numeric', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
    const d = new Date(row.createdOn);
  
    return <div>
        <h6>{row.note}</h6>
        <div className="footnote">
            <span><i>Created By: { row.fullName }</i></span>
            <span><i> | </i></span>
            <span><i>Created On: { d.toLocaleDateString(undefined, options) }</i></span>
        </div>
    </div>
}

const columns = [{
        dataField: '_id',
        text: 'ID',
        hidden: true
    }, {
        dataField: 'note',
        text: 'Note',
        formatter: noteFormatter,
        headerStyle: {
            display: 'none'
        }
}];

export default ({
    data,
    onNoteClick
  }) => 
    <BootstrapTable 
        keyField="_id"
        noDataIndication="No Notes"
        data={ data } 
        bootstrap4
        hover
        clickable
        rowEvents={ {
            onClick: (e, row, rowIndex) => {
                onNoteClick(row);
        } } }
        columns={ columns }>
    </BootstrapTable>;